// Wee Dropdown (weepower.com)
// Licensed under Apache 2 (http://www.apache.org/licenses/LICENSE-2.0)
// DO NOT MODIFY

Wee.fn.make('dropdown', {
	_construct: function() {
		this.namePrefix = 'dropdown-';
	},
	init: function(settings) {
		var scope = this,
			conf = Wee.$extend({
				activeClass: '-is-active',
				event: 'click',
				isExpanded: false,
				placeholder: 'Select One',
				selector: 'ref:weeDropdown',
				template: '{{ text }}'
			}, settings),
			$selects = $(conf.selector).filter('select');

		scope.conf = conf;

		if ($selects.length) {
			// Create template partials
			Wee.view.addView('optionView', conf.template);
			Wee.view.addView('labelView', conf.labelTemplate || conf.template);

			// Build/configure dropdowns from select elements
			$selects.each(function(select, i) {
				var data = scope.buildOptions(select),
					name = scope.namePrefix + i,
					$root = $('<div class="dropdown" />'),
					$select = $(select);

				$root.data('id', name);

				if (conf.rootModifier) {
					$root.addClass(conf.rootModifier);
				}

				// Add dropdown immediately after select
				$select.after($root);

				Wee.app.make(name, {
					target: $root,
					view: 'dropdown.view',
					model: {
						activeClass: conf.activeClass,
						isExpanded: conf.isExpanded,
						name: name,
						placeholder: conf.placeholder,
						options: data.options,
						selection: data.selection,
						$root: $root,
						$select: $select
					}
				});

				scope.bindLabelEvents(name);
				scope.bindContentEvents(name);
			});

			Wee.$setRef(conf.selector);
		}
	},

	/**
	 * Update contents of specific dropdown
	 *
	 * @param {HTMLElement} selector
	 */
	update: function(selector) {
		var scope = this,
			$select = $(selector),
			name = $select.next()
				.data('id'),
			model = Wee.$extend(true, {}, Wee.app[name].$get()),
			data = scope.buildOptions($select[0]);

		model.options = data.options;
		model.selection = data.selection;

		Wee.app[name].$set(model);
	},

	/**
	 * Open specified dropdown
	 *
	 * @param {string} name
	 */
	open: function(name) {
		Wee.app[name].$set('isExpanded', true);
	},

	/**
	 * Close specified dropdown
	 *
	 * @param {string} name
	 */
	close: function(name) {
		Wee.app[name].$set('isExpanded', false);

		$(Wee._doc).off('.weeDropdown');
	},

	/**
	 * Bind custom event used for opening/closing dropdown
	 *
	 * @param {string} name
	 */
	bindLabelEvents: function(name) {
		var scope = this,
			conf = scope.conf,
			$root = Wee.app[name].$get('$root'),
			$label = $('ref:dropdownLabel', $root);

		if (conf.event == 'mouseenter') {
			// Mouseenter requires different interactions than all others
			$label.on({
				'mouseenter.weeDropdown': function() {
					scope.open(name);
				},
				'mouseleave.weeDropdown': function() {
					scope.close(name);
				}
			}, {
				delegate: $root
			});
		} else {
			$label.on(conf.event + '.weeDropdown', function() {
				if (Wee.app[name].$get('isExpanded')) {
					scope.close(name);
				} else {
					scope.open(name);

					setTimeout(function() {
						$(Wee._doc).on({
							'click.weeDropdown': function() {
								scope.close(name);
							},
							'keyup.weeDropdown': function(e) {
								if (e.keyCode === 27) {
									scope.close(name);
								}
							}
						});
					}, 50);
				}

				if (conf.labelClick) {
					conf.labelClick(name, $root);
				}
			}, {
				delegate: $root
			});
		}
	},

	/**
	 * Bind events to dropdown content
	 *
	 * @param {string} name
	 */
	bindContentEvents: function(name) {
		var scope = this,
			conf = scope.conf,
			$root = Wee.app[name].$get('$root'),
			$select = Wee.app[name].$get('$select');

		$('ref:dropdownItem', $root.find('ref:dropdownContent'))
			.on('click.weeDropdown', function(e, el) {
				var index = $(el).data('index'),
					model = Wee.$extend(true, {}, Wee.app[name].$get()),
					option = model.options[index];

				if (option.isDisabled) {
					return;
				}

				// Set text of dropdown selection
				model.selection = Wee.$extend(true, {}, option);
				model.options.forEach(function(option, i) {
					option.isSelected = i === index;
				});
				Wee.app[name].$set(model);

				// Set value of hidden select
				// val must match option value on DOM to be applied.
				// All values on DOM are string values
				$select.val(option.value.toString());

				if (conf.itemClick) {
					conf.itemClick(option, el, name);
				}
			}, {
				delegate: $root
			});
	},

	/**
	 * Build dropdown configuration details
	 *
	 * @param {HTMLElement} select
	 * @returns {{options: *, selection: *}}
	 */
	buildOptions: function(select) {
		var scope = this,
			selection = null,
			options = Wee._slice
				.call(select.options)
				.map(function(el) {
					var $el = $(el),
						selected = $el.attr('selected'),
						option = Wee.$extend({
							isDisabled: $el.prop('disabled'),
							isSelected: selected,
							modifierClass: scope.conf.childModifier,
							text: $el.text(),
							value: $el.val()
						}, $el.data('model'));

					if (selected !== null) {
						selection = Wee.$extend(true, {}, option);
					} else {
						// Set select default value to nothing
						// without requiring empty <option> tag
						select.value = '';
					}

					return option;
				});

		return {
			options: options,
			selection: selection
		};
	}
});