Wee.fn.make('social', {
	init: function() {
		this.$private.socialSideFade();
	},

	stickyScroll: function() {
		var $win = $($._win),
			$socialSide = $('ref:socialSide'),
			elPos = $socialSide.offset().top,
			isFloated = '-is-floated',
			scrolled = false;

		$win.on('scroll', function() {
			var winPos = $win.scrollTop();

			if (! scrolled && winPos > (elPos - 85)) {
				$socialSide.addClass(isFloated);

				scrolled = true;
			}

			if (scrolled && (elPos - 85) > winPos) {
				$socialSide.removeClass(isFloated);

				scrolled = false;
			}
		});
	}
}, {
	socialSideFade: function() {
			var $socialSide = $('ref:socialSide'),
				$secondaryContent = $('ref:secondaryContent'),
				hidden = false;

		$($._win).on('scroll', function() {
			if (! hidden) {
				if ($socialSide.offset().top > ($secondaryContent.offset().top - 450)) {
					$socialSide.invisible();

					hidden = true;
				}
			}

			if ($socialSide.offset().top < ($secondaryContent.offset().top - 450) && hidden) {
				$socialSide.visible();

				hidden = false;
			}
		});
	}
});