Wee.fn.make('gridSection', {
	init: function() {
		var priv = this.$private,
			$sections = $('ref:gridSection'),
			resizeGrid;

		priv.setGridSectionHeights();

		window.onresize = function() {
			clearTimeout(resizeGrid);

			resizeGrid = setTimeout(function() {
				$sections.css('height', 'auto');

				priv.setGridSectionHeights();
			}, 100);
		};
	}
}, {
	setGridSectionHeights: function() {
		var $sections = $('ref:gridSection'),
			tallestSection = 0;

		$sections.each(function() {
				var currentHeight = $(this).height();

				if (currentHeight > tallestSection) {
					tallestSection = currentHeight;
				}
			}
		);

		$sections.height(tallestSection);
	}
});