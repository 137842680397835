Wee.fn.make('common', {
	init: function() {
		var priv = this.$private;

		$.screen.map([
			{
				min: 4,
				callback: function() {
					priv.detachHeader(true);
				}
			},
			{
				min: 1,
				max: 3,
				callback: function() {
					priv.detachHeader(false);
				}
			}
		]);

		priv.navToggle();
		priv.chain();

		Wee.dropdown.init();
	}
}, {
	/**
	 * Custom chainable methods
	 *
	 * @return void
	 */
	chain: function() {
		var isDisabled = '-is-disabled',
			isVisible = '-is-visible',
			isActive = '-is-active';

		$.chain({
			enable: function() {
				this.removeClass(isDisabled);

				return this;
			},
			disable: function() {
				this.addClass(isDisabled);

				return this;
			},
			isDisabled: function() {
				return this.hasClass(isDisabled);
			},
			visible: function() {
				this.addClass(isVisible);
			},
			invisible: function() {
				this.removeClass(isVisible);
			},
			isVisible: function() {
				return this.hasClass(isVisible);
			},
			isActive: function() {
				return this.hasClass(isActive);
			},
			activate: function() {
				return this.addClass(isActive);
			},
			deactivate: function() {
				return this.removeClass(isActive);
			},
			toggleActive: function() {
				return this.hasClass(isActive) ?
					this.removeClass(isActive) :
					this.addClass(isActive);
			}
		});
	},

	/**
	 * Navigation Toggle
	 *
	 * @return void
	 */
	navToggle: function() {
		var $body = $($._body),
			$overlay = $('ref:navOverlay'),
			$text = $('ref:navToggleText'),
			expanded = 'aria-expanded';

		$('ref:navToggle').on('click', function() {
			var $this = $(this);

			if (! $overlay.isVisible()) {
				$overlay.show();
				$body.disable();

				$this.attr(expanded, true);

				$._win.setTimeout(function() {
					$overlay.visible();
				}, 50);
			} else {
				$overlay.invisible();
				$body.enable();

				$this.attr(expanded, false);

				$._win.setTimeout(function() {
					$overlay.hide();
				}, 200);
			}
		});
	},

	/**
	 * Detach header on scroll
	 *
	 * @param bind bool
	 */
	detachHeader: function(bind) {
		var $header = $('ref:stickyHeader'),
			$donate = $('ref:stickyDonate'),
			$win = $($._win),
			scrolled = false,
			sticky = '-is-sticky',
			visible = '-is-visible';

		if (bind) {
			$win.on('scroll', function() {
				var pos = $win.scrollTop();

				if (pos > 300 && ! scrolled) {
					$header.addClass(sticky);
					$donate.show();

					$._win.setTimeout(function() {
						$header.addClass(visible);
					}, 100);

					scrolled = true;
				} else if (pos < 300 && scrolled) {
					$header.removeClass(visible);

					$._win.setTimeout(function() {
						$header.removeClass(sticky);
						$donate.hide();
					}, 200);

					scrolled = false;
				}
			});
		} else {
			$header.removeClass(sticky);

			$win.off();
			$donate.hide();
		}
	}
});