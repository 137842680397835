Wee.fn.make('donate', {
	init: function() {
		var priv = this.$private;

		priv.animateLabels();
		priv.checkBoxes();
		priv.calculateScriptures();
	}
}, {
	/**
	 * Animate labels
	 */
	animateLabels: function() {
		var $input = $('ref:donateInput'),
			label = 'ref:donateLabel';

		$input.on('focus', function() {
			var $this = $(this);

			$this.activate()
				.next(label)
				.activate();
		});

		$input.on('blur', function() {
			var $this = $(this);

			if ($this.val() === '') {
				$this.deactivate()
					.next(label)
					.deactivate();
			}
		});
	},

	checkBoxes: function() {
		$('ref:checkBox').on('click', function() {
			$(this).parent('ref:checkBoxLabel')
				.toggleClass('-is-checked');
		});

		$('ref:checkBoxRecurring').on('change', function() {
			$('ref:checkBoxLabelRecurring').toggle();
		});
	},

	calculateScriptures: function() {
		var scriptureCost = 2;

		$('ref:donationAmount').on('keyup', function() {
			var $this = $(this),
				value = $this.val(),
				scriptures = Math.ceil(value * scriptureCost);

			$('ref:scriptureAmount').text(scriptures);
		});
	}
});