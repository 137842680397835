Wee.routes.map({
	'$any': [
		'common',
		'navigation'
	],
	'$root': [
		'home',
		'gridSection'
	],
	'about': 'gridSection',
	'blog': {
		'$root': [
			'blog'
		],
		'$any': [
			'gridSection'
		],
		'$slug': [
			'social',
			'social:stickyScroll'
		]
	},
	'read-the-bible': {
		'$any': [
			'gridSection',
			'social',
			'social:stickyScroll',
			'readTheBible'
		]
	},
	'donate': 'donate',
	'financials': 'gridSection',
	'resources': 'gridSection',
	'get-involved': 'gridSection',
	'contact-us': 'gridSection',
	'become-a-christian': [
		'donate',
		'gridSection'
	],
	'pray-with-us': [
		'donate',
		'gridSection'
	]
});

Wee.ready('routes:run');