/* global Masonry */

Wee.fn.make('blog', {
	init: function() {
		var $spinner = $('ref:spinner');

		Wee.masonWee.init('blogs');

		$.screen.map([
			{
				min: 1,
				max: 2,
				callback: function() {
					Wee.masonWee.update('blogs', {
						columns: 1,
						loading: true,
						callback: function() {
							$spinner.hide();
						}
					});
				}
			},
			{
				min: 2,
				max: 3,
				callback: function() {
					Wee.masonWee.update('blogs', {
						columns: 2,
						loading: true,
						callback: function() {
							$spinner.hide();
						}
					});
				}
			},
			{
				min: 4,
				callback: function() {
					Wee.masonWee.update('blogs', {
						columns: 3,
						loading: true,
						callback: function() {
							$spinner.hide();
						}
					});
				}
			}
		]);
	}
});