Wee.fn.make('readTheBible', {
	init: function() {
		var $tabs = $('ref:tab');

		$tabs.on('click', function() {
			var $this = $(this),
				type = $this.data('type');

			$('ref:kjv').hide();
			$('ref:esv').hide();

			$tabs.deactivate();
			$this.activate();

			$('ref:' + type).show();
		});
	}
});