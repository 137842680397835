Wee.fn.make('easySlide', {
	init: function(options) {
		var	scope = this,
			config = $.extend({
				arrows: false,
				thumbs: false,
				resizeParent: false
			}, options);

		scope.$slideElements = $('ref:' + config.elementRef);
		scope.$slideTrackers = $('ref:' + config.trackerRef);
		scope.$parent = this.$slideElements.parent();
		scope.resizeParent = config.resizeParent;

		scope.index = 0;

		if (scope.resizeParent) {
			scope.$parent.height(this.$slideElements.first().height());
		}

		if (config.arrows) {
			var $arrows = $('ref:' + config.arrows),
				disabledClass = '-is-disabled';

			scope.$leftArrow = $arrows.first();
			scope.$rightArrow = $arrows.last();

			scope.$leftArrow.on('click', function() {
				if (! scope.$leftArrow.hasClass(disabledClass)) {
					scope.cycle(scope.index - 1, 'left');
				} else {
					console.log('test');
					scope.cycle(scope.$slideTrackers.length - 1, 'left');
				}
			});

			scope.$rightArrow.on('click', function() {
				if (! scope.$rightArrow.hasClass(disabledClass)) {
					scope.cycle(scope.index + 1, 'right');
				} else {
					scope.cycle(0, 'right');
				}
			});
		}

		scope.$slideTrackers.on('click', function(e, el) {
			var index = $(el).index();

			scope.cycle(index, 'thumb');
		});

		$.events.on(scope.$slideElements, {
			swipeRight: function() {
				scope.cycle(scope.index - 1, 'left');
			},
			swipeLeft: function() {
				scope.cycle(scope.index + 1, 'right');
			},
			dragstart: function(e) {
				e.preventDefault();
			}
		});

		if (config.thumbs) {
			$($._win).on('resize init', function() {
				scope.calculateWidths();
			});

			scope.setupThumbs(config);
		}
	},

	setupThumbs: function() {
		if (this.overflow) {
			var $thumbs = $('ref:imgThumb');

			this.thumbWidth = $thumbs.width();
		}
	},

	calculateWidths: function() {
		this.totalWidth = $('ref:thumbsWrap').width();
		this.containerWidth = $('ref:thumbs').width();
		this.overflow = this.totalWidth > this.containerWidth;
	},

	reCalculateParent: function() {
		this.$slideElements.parent().height(this.$slideElements.eq(this.index).height());
	},

	moveThumbs: function(action, index) {
		if (this.overflow) {
			var $wrapper = $('ref:thumbsWrap'),
				left = this.$slideTrackers.not('.-is-disabled').position().left,
				half = this.containerWidth / 2,
				wrapLeft = $('ref:thumbsWrap').position().left;

			if (action === 'right') {
				if ((left + this.thumbWidth) > half) {
					var remaining = this.totalWidth - this.containerWidth + wrapLeft;

					if (remaining > 0) {
						var minLeft = (this.totalWidth - this.containerWidth) * -1;

						$wrapper.css('left', Math.max(minLeft, (wrapLeft - this.thumbWidth)) + 'px');
					}
				}
			} else if (action === 'left') {
				if ((left + wrapLeft) < half && wrapLeft) {
					$wrapper.css('left', Math.min(0, (wrapLeft + this.thumbWidth)) + 'px');
				}
			} else {
				if (index < this.index && (left + wrapLeft) < half && wrapLeft) {
					this.moveThumbs('left');
				} else if (index > this.index && (left + this.thumbWidth) > half) {
					this.moveThumbs('right');
				}
			}
		}
	},

	cycle: function(index, action) {
		var scope = this,
			selected = 'aria-selected',
			disabledClass = '-is-disabled';

		if (index < 0 || index > scope.total) {
			return;
		}

		if (this.resizeParent) {
			this.$slideElements.parent().height(this.$slideElements.eq(index).height());
		}

		scope.$slideTrackers.eq(index)
			.removeClass(disabledClass)
			.attr(selected, true)
			.siblings()
			.addClass(disabledClass)
			.attr(selected, false);

		scope.$slideElements.eq(index)
			.removeClass(disabledClass)
			.attr(selected, true)
			.siblings()
			.addClass(disabledClass)
			.attr(selected, false);

		if (scope.$leftArrow) {
			if (! index) {
				scope.$leftArrow.addClass(disabledClass);
			} else if (! scope.index) {
				scope.$leftArrow.removeClass(disabledClass);
			}

			if (index === scope.$slideTrackers.length - 1) {
				scope.$rightArrow.addClass(disabledClass);
			} else if (scope.index === scope.$slideTrackers.length - 1) {
				scope.$rightArrow.removeClass(disabledClass);
			}
		}

		if (action) {
			scope.moveThumbs(action, index);
		}

		$('ref:currentImgNum').text(index + 1);

		scope.index = index;
	}
});