Wee.fn.make('home', {
	init: function() {
		var priv = this.$private;

		priv.initSlider();
	}
}, {
	initSlider: function() {
		Wee.easySlide.init({
			elementRef: 'contentSlider',
			arrows: 'arrow',
			trackerRef: 'dot'
		});
	}
});